nav {
    display: flex;
}

.header-navigation-container {
    z-index: 10000;
    display: flex;
    flex-direction: column;


}

.close-filled-container {
    width: 95%;
    height: 10vh;
    /* border: solid red 2px; */
    /* background-color: black; */

    display: flex;
    justify-content: flex-end;
    align-items: flex-start;

    padding: 2% 0;
}

.bottom-header-container {
    border-radius: 5px;
    width: 45%;
    max-height: 5vh;
    /* position: relative; */
}

.bottom-header-container .logo-container {
    height: 4em;
    aspect-ratio: 23/30;
}

.burger-nav-container {
    width: 100%;
    display: flex;
    align-items: center;
    margin-left: .5em;
}

.top-category-ul {
    text-align: center;
    width: 100%;
}

.top-category-ul>li {
    width: 100%;
    overflow: hidden;
    padding: 2% 0;
    margin: 2% 0;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.top-category-ul>li>p,
.top-category-ul>li>a {
    text-transform: uppercase;
}

.top-category-ul a,
.top-category-ul p,
.footer-top-category-ul a,
.footer-top-category-ul p {
    width: 100%;
    line-height: 2em;
    font-family: var(--text-font);
    font-size: 1.4rem;
    font-weight: bold;
    cursor: pointer;
}

.top-category-ul a,
.top-category-ul p {
    font-size: 1.8rem;
}

.sub-category-ul {
    font-family: var(--text-font);
    font-weight: bold;

    max-height: 35vh;
    overflow-y: scroll;

    /* background-color: var(--yellow); */
    color: var(--white);
    box-shadow: rgba(0, 0, 0, 0.44) 0.1em 0.1em 1em;
    /* width: 80%; */
    margin-left: auto;
    margin-right: auto;
    border-radius: 0 1em;
}

.sub-category-ul li p,
.sub-category-ul li a {
    color: var(--black);
}

.header-services-ul>li {
    width: 100%;
    padding: 2%;
    /* border-bottom: solid 2px var(--red); */
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}

@media screen and (min-width: 768px) {
    .bottom-header-container .logo-container {
        aspect-ratio: 778/315;
        cursor: pointer;
    }
}

@media screen and (min-width: 768px) {
    .bottom-header-container {
        width: 100%;
        max-height: 50%;
        padding: .4em .4em 0;
        align-items: end;
        justify-content: space-between;
    }

    .header-navigation-container {
        width: 90rem;
        height: 100%;
        justify-content: end;

    }

    .bottom-header-container .logo-container {
        margin-right: 2%;
        height: 6em;
    }

    .top-category-ul {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 0;
        padding: 0;
        margin-left: auto;
    }

    .top-category-ul a {
        display: block;
        height: 100%;
        padding-top: .4em;
        padding-bottom: .4em;
    }

    .top-category-ul>li {
        margin: 0;
        padding: 0;
        background-color: var(--red);
        height: 3em;
        line-height: 1em;
        padding: 0.4em .4em 0;
        margin: 0 0.2em;
        border-radius: 5px 5px 0 0;
    }

    #link-to-enriquezmenager {
        background-color: var(--yellow);
    }

    #link-to-enriquezmenager a {
        color: var(--black);
    }

    .top-category-ul p, .top-category-ul a {
        font-size: 1rem;
        line-height: normal;
        color: var(--white);
        height: 100%;
        width: 100%;
        text-align: center;
    }

    .sub-category-ul {
        max-height: 55vh;
        border: solid var(--red) 2px;
        overflow: scroll;
        background-color: var(--white);
        font-size: .8em;
        /* color: var(--white); */
        padding: 0 2%;
        position: absolute;
        top: 100%;
        overflow: hidden;
    }

    .sub-category-ul a {
        font-size: 1.2em;
    }
}