#auth-container {
    text-align: center;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: var(--admin-background);
}

.auth-forms-container {
    width: 70%;
    margin: auto auto;
    text-align: center;
    padding: 5%;
    margin: 3% 0;

    /* border: solid var(--red) 2px; */
    border-radius: 2px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#auth-container h1 {
    font-family: var(--admin-text-font);
    text-align: center;
    font-size: 1.4em;

    /* position: sticky;
    top: 0; */

    border-bottom: solid 2px var(--admin-background);
    /* background-color: var(--admin-background); */
    /* color: var(--white); */
}



#auth-container form input {
    width: 50%;
}

#auth-container form label {
    margin: 2% 0;
}

#auth-container form button {
    padding: 1% 2%;
    width: 50%;

    margin: 4% auto;

    background-color: var(--admin-background);

    border: none;
    border-radius: 4px;

    color: var(--white);

    cursor: pointer;

}

@media screen and (min-width: 768px) {}

@media screen and (min-width: 968px) {
    #auth-container .input-group {
        width: 100%;
    }
}