.realisation-title-image {
    height: 20vh;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;

}

.realisation-title-container {
    height: 100%;
    background: rgba(250, 250, 250, .6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1em;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;


}



.yellow-polygon {
    background-color: var(--yellow);
    clip-path: polygon(0 0, 100% 28%, 100% 100%, 0 75%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: -1;
}

.single-realisation-container {
    padding: 0 3%;
    position: relative;
    margin-top: 3%;

}

#single-realisation-main h1 {
    text-align: center;
    font-size: 2em;
}


.realisation-gallery-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 40vh;
    width: 90%;
    padding: 3%;
    margin: 0 auto;
    border-radius: 2px;

    background-color: var(--white);

    padding: 2%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;


}

.selected-gallery-container {
    width: 100%;
    height: 70%;
    cursor: pointer;
}

.selected-gallery-container img {
    object-fit: contain;
    object-position: center center;

}

.miniatures-list-container {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;

    height: 30%;
    gap: 0.375rem;
    overflow-x: scroll;
    background-color: var(--white);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    margin-top: 1rem;
}

.realisation-gallery-miniatures {
    width: 30%;
    height: 10vw;
    box-shadow: 0 7px 29px 0 #64646f33;
    cursor: pointer;

    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;


}

.realisation-gallery-miniatures img {
    object-fit: cover;
}

.realisation-content-container {
    margin-top: 5%;
    background-color: #fff;
    padding: 2em;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}

.realisation-navigation-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    margin: 3% 0;
}

.realisation-list-pagination {
    width: 70%;
    margin: 2% auto;
    padding: 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.realisation-navigation-text-icon-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    height: 100%;


}

.realisation-navigation-text-icon-container h3 {
    font-size: 1em;
    font-family: var(--text-font);
    font-weight: 500;

    padding-bottom: 0;

}

.realisation-nav-next {
    align-items: flex-end;

}

.page-link {
    display: block;
    padding: .375rem 1rem;
    cursor: pointer;

    /* border: solid var(--black) 1.2px; */
    border-radius: 8px;

    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.disabled .page-link {
    background-color: lightgray;
    color: var(--white);
    /* border: solid lightgray 1.2px; */

}

.page-item:not(.disabled) .page-link:hover {
    background-color: var(--black);
    /* border: solid var(--red) 1px; */

    font-weight: bold;
    color: var(--white);
    scale: 1.1
}

.pagination-active .page-link {
    background-color: var(--black);
    /* border: solid var(--red) 1px; */
    font-weight: bold;

    color: var(--white);
    scale: 1.1
}

.modale-picture {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    height: 70vh;
    width: 90%;
    margin: auto auto;


    z-index: 100;

    padding: 2%;

    background-color: var(--white);

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

.modale-picture-container {
    width: 100%;
    height: 95%;
}

.modale-picture-container img {
    object-fit: contain;
}



/* ********************************* REALISATION LISTE ********************************* */
#realisation-container h1 {
    text-align: center;
    padding-bottom: 0.375rem;
}

#realisation-container .input-group {
    width: 70%;
    margin: 2% auto;
}


#realisation-container .input-group select {
    width: 100%;
}

@media screen and (min-width: 768px) {
    .realisation-gallery-container {
        height: 45vh;
        flex-direction: row;
    }

    .selected-gallery-container {
        width: 70%;
        height: 100%;
    }

    .miniatures-list-container {
        width: 27%;
        height: 100%;
        margin-top: 0;

        padding: 0.375rem;

        align-items: flex-start;
        justify-content: flex-end;
        flex-wrap: wrap;
    }

    .realisation-gallery-miniatures {
        width: 45%;
    }


}

@media screen and (min-width: 968px) {

    .realisation-gallery-container {
        height: 55vh;
    }

    .realisation-content-container {
        padding: 4em;
    }
}