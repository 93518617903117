.backoffice-header {
    max-width: 100%;
    padding: 1%;
    margin: 0 auto;

    background: rgb(255, 255, 255);
    background: linear-gradient(264deg, rgba(255, 255, 255, 1) 0%, rgba(234, 242, 254, 1) 0%, rgba(202, 222, 253, 1) 4%, rgba(117, 168, 250, 1) 24%, rgba(22, 108, 247, 1) 72%);
}

.backoffice-header-button-container {
    width: 100%;

    display: flex;
    justify-content: flex-end;
    align-items: center;


}

.backoffice-header a {
    padding: 1%;
    margin: 0 2%;

    background-color: #0f52bf;
    border: none;
    border-radius: 5px;

    color: var(--white);

    cursor: pointer;
}



#backoffice-home-main h1 {
    text-align: center;
    background-color: var(--admin-background);
    color: var(--white);
    font-family: var(--admin-text-font);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}

.backoffice-home-ul-container h2 {
    font-family: var(--admin-text-font);

}

.backoffice-home-ul-container {
    padding: 5%;

    display: flex;
    justify-content: space-around;
    flex-direction: column;
    text-align: center;

}



.collection-manager-ul {
    border: solid var(--admin-background) 2px;
    margin-bottom: 5%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.collection-manager-ul h2 {
    background-color: var(--admin-background);

    color: var(--white);
    padding: 4% 0;

}

.collection-manager-ul ul li {
    padding: 2%;

    border-bottom: solid 2px var(--admin-background);
}

.collection-manager-ul ul li a {

    /* color: var(--white); */
    font-size: 1.2em;
    font-weight: 500;


}

/* @media screen and (min-width: 768px) {
   

} */

@media screen and (min-width: 968px) {
    .backoffice-home-ul-container {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .collection-manager-ul {
        width: 40%;
    }

    .collection-manager-ul ul li {
        height: 100%;
    }

}