.information-message-container {
    color: #fff;
    /* width: 80%; */
    margin: 0 auto;
    text-align: center;
    border-radius: 5px;
    padding: 5%;
    position: relative;
    width: 25vw;
}

.information-message-close-container {
    position: absolute;
    top: 5%;
    right: 5%;

    cursor: pointer;


}

.information-message-title {
    margin: 0;
    margin-bottom: 5%;
    /* font-size: larger; */
    font-weight: bold;
    text-decoration: underline;
    text-underline-offset: 5px;


}

.information-message-content {
    margin: 0;
    /* font-size: large; */


}

.information-message-ul {
    position: fixed;
    bottom: 0;
    right: 5%;
    display: flex;
    flex-direction: column;
    list-style: none;
    justify-content: flex-end;
    height: 25vh;

    z-index: 100000;

}

.information-message-ul li {
    width: 300px;
    background: white;
    margin: 10px;
    flex: 0 0 100px;
    position: relative;
    border-radius: 10px;
    padding: 3%;

}