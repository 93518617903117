#modale {
    z-index: 10;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;


    margin: auto auto;
    padding: 3%;
    border-radius: 5px;

    color: var(--black);
    text-align: center;

    background-color: var(--white);
    border: solid 2px var(--red);

    width: 33vw;
    /* min-height: 25vh; */
    height: 45vh;


    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}



.close-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

}

#modal div {
    width: 100%;
    display: flex;
    justify-content: space-around;
    text-align: center;
}

.modal-delete-button-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: blue solid 2px;
    width: 100%;
}

.modal-delete-button-container button {
    padding: 2% 3%;

    border: none;

}