.footer {
    padding: 2em 0;
    background-color: rgb(36, 36, 36);
    color: #fff;
    text-align: center;
}

.footer-link-container a {
    color: #fff;
}

.footer h2 {
    font-family: var(--title-font);
    font-weight: bold;

    text-decoration: underline;
    text-underline-offset: 0.3em;
    text-decoration-color: var(--red);

}

.upper-footer div {
    margin-bottom: 1.5em;
}

.footer ul {
    padding-left: unset;
}

.footer p, .footer li, .footer a {
    text-decoration: none;

    color: #fff;
    font-family: var(--text-font);

    margin-bottom: 0.5em;

}

.footer h2 {
    font-family: var(--text-font);
    margin-bottom: 2%;
}

#map {
    width: 100%;
}

.sitemap {
    padding: 0 2%;
}

.footer-navigation-container {
    margin: 2% auto;
    text-align: left;

    padding: 0 3%;


}

.footer-navigation-container ul {
    width: 100%;
}

.footer-link-container::after {
    content: "";
    display: block;
    height: 1px;
    background-color: #fff;
    margin-bottom: 1em;
}

#centcommentaire {
    text-decoration: underline;
}

.footer-services-ul li, .header-services-ul li {
    padding: 2%;
}



@media screen and (min-width: 768px) {
    .upper-footer {
        display: flex;
        flex-wrap: wrap;
    }

    .adress {
        width: 100%;
    }

    .openning-hours,
    .footer-contact {
        width: 50%;
    }

    .footer-contact li {
        list-style: none;
    }

    .footer li ul {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (min-width: 992px) {
    .upper-footer {
        justify-content: center;
    }

    .footer-navigation-container {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }

    .footer-navigation-container ul {
        display: flex;
        justify-content: space-between;
    }

    .openning-hours,
    .footer-contact {
        width: 30%;
    }

    #map {
        padding-top: 3em;
        width: 30%;
    }
}