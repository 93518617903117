#client-main {
    min-height: 45vh;
}

#client-main h2, .service-article h3 {
    text-align: center;
}



#client-main h3 {
    margin-bottom: 2%;
}

.category-title-container {
    height: 100%;
    background: rgba(250, 250, 250, .4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1em;
}

.category-title-container h1 {
    padding: 0;
    text-align: center;
}

.category-title-container p {
    text-align: center;
}

.category-title-image {
    height: 40rem;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
}

#client-main section {
    width: 100%;
}

.section-details-container {
    padding: 0 3%;
    text-align: justify;
}

.service-article {
    padding: 3%;
    margin: 5% 0;
}

.service-article-picture-container {
    width: 90%;
    margin: 0 auto;
}

.service-article-picture-container img {
    object-fit: contain;
    aspect-ratio: 16/9;
}

.service-article-content {
    margin-top: 2%;
}



.service-article-container>article:nth-child(odd) {
    background-color: var(--yellow);
}



.picto-border {
    margin: 1em auto;
    display: flex;
    justify-content: center;
    gap: 4em;
}

.picto-border::after,
.picto-border::before {
    content: "";

    display: block;

    height: 2px;

    width: 25vw;

    margin-top: auto;

    margin-bottom: auto;

    background-color: var(--black);

}

.picto-border img {
    width: 50px
}

.card-button {
    padding: 2%;
    border: none;
    color: var(--black);

    border-radius: 5px;

    display: block;
    margin: 4% auto;

}


.service-article-container>article:nth-child(odd) .card-button {
    background-color: var(--white);
}

.service-article-container>article:nth-child(even) .card-button {
    background-color: var(--yellow);
}

.card-button:hover {
    cursor: pointer;
    opacity: 0.7;

}







/* #client-main .first-article-section {
    background-color: var(--yellow);
    margin-top: 0;
    clip-path: polygon(0 0, 100% 15%, 100% 100%, 0px 85%);
    padding-bottom: 25vw;
    border-radius: .5em;
    padding-top: 15vw;
} */





@media screen and (min-width: 768px) {
    #client-main h2 {
        color: var(--yellow);
    }

    #client-main .section-details-container {
        text-align: center;
    }

    #client-main .service-article-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 3%;
    }

    #client-main .service-article-container article {
        width: 40%;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        background-color: var(--white);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border-radius: 0 1em;
    }

    #client-main .service-article-container article .card-button {
        background-color: var(--yellow);
    }

    #client-main .service-article-container article .service-article-picture-container {
        order: 1;
    }

    #client-main .service-article-container article .service-title-container {
        order: 2;
    }

    #client-main .service-article-container article .service-article-content {
        order: 3;
    }



    #client-main .service-article-container .one-article {
        width: 100%;
        display: grid;
        grid-column: 5;
        grid-row: 6;
    }

    #client-main .one-article .service-article-picture-container:nth-child(odd) {
        grid-column-start: 3;
        grid-column-end: 5;

    }

    #client-main .one-article .service-article-picture-container:nth-child(even) {
        grid-column-start: 1;
        grid-column-end: 3;

    }

    #client-main .service-article-container .one-article .service-article-picture-container {
        grid-row-start: 1;
        grid-row-end: 6;
    }

    #client-main .service-article-container .one-article .service-title-container {
        grid-row-start: 2;
        grid-row-end: 3;
    }


    #client-main .service-article-container .one-article .service-article-content {
        grid-row-start: 3;
        grid-row-end: 4;
    }

    .picto-border::after,
    .picto-border::before {
        background-color: var(--yellow);
    }
}