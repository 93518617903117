.backoffice-form-main img {
    /* width: 1%; */
}

.backoffice-form-main h1 {
    font-family: var(--admin-text-font);
    text-align: center;
    font-size: 1.4em;

    /* position: sticky;
    top: 0; */

    border-bottom: solid 2px var(--admin-background);
    /* background-color: var(--admin-background); */
    /* color: var(--white); */
}

.form-languages-select-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.backoffice-form-container {
    width: 85%;
    margin: 2% auto;
}

.backoffice-language-selection-container {
    width: 100%;

    display: flex;
    justify-content: flex-end;
    align-self: center;
}

.backoffice-flag-wrapper {
    width: 30%;

    display: flex;
    justify-content: space-around;
    align-items: center;
}

.backoffice-flag-picture-container {
    width: 30%;
}

.input-robot {
    display: none;
}

.required-span {
    color: var(--red);
}

.input-group {
    margin: 3% 0;
    /* border: solid var(--admin-background) 1px; */

    padding: 2%;

    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.input-group label {
    font-size: 1em;
    font-weight: bold;

}

.label-opacity-wrapper {
    margin-bottom: 2%;

}

.input-group input, .input-group select {
    width: 90%;
    padding: 2%;
    font-weight: bold;
    border-radius: 4px;
}

.backoffice-form-container form button {
    padding: 2%;
    border: none;
    border-radius: 5px;
    background-color: var(--admin-background);
    color: var(--white);

    display: block;
    margin: 0 auto;

    cursor: pointer;
}

#ck-editor-container {
    width: 100%;
}

.ck-content {
    min-height: 30vh;
}

.form-error-container {
    color: var(--red);
    font-weight: bold;
    margin-top: 2%;
    text-align: center;
}

.previw-picture-img-container {
    width: 20vw;
    height: 20vw;

    margin: 0 auto 3% auto;
}

.preview-gallery-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.preview-gallery-single-img-container {
    width: 20vw;
    height: 20vw;

    position: relative;
}

.preview-gallery-single-img-container svg {
    position: absolute;
    top: .375rem;
    right: .375rem;
}

#user-create-main {
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    align-items: center;

    padding: 0 2%;
}

#user-create-main h1 {
    padding-bottom: 0;
}

#user-create-main form {
    width: 70%;
}

#user-create-main div {
    width: 100%;
}

#user-create-main button {
    display: block;
    margin: 0 auto;

    background-color: var(--admin-background);
    border: none;
    color: var(--white);

    padding: 2%;
    border-radius: 4px;
    cursor: pointer;
}

#user-create-main button:hover {

    background-color: #0953ca;
}

@media screen and (min-width: 968px) {
    .backoffice-flag-wrapper {
        width: 20%;
    }

    .input-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .input-group {
        width: 45%;
    }

    .backoffice-form-container form button {
        width: 30%;
    }
}