/* IMPORT RESET.CSS */
@import url("./assets/styles/reset.css");
/* @import url("https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"); */
/* IMPORT FONTs */
@import url('https://fonts.googleapis.com/css2?family=Galada&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

/* IMPORT CDN */
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css');

/* IMPORT PAGE STYLES */
@import url("./Client/Home/style.css");
@import url("./Backoffice/Auth/style.css");
@import url("./Client/ClientPage/style.css");
@import url("./Client/Contact/style.css");
@import url("./Client/Realisation/style.css");



@import url("./Backoffice/BackofficeHome/style.css");
@import url("./Backoffice/BackofficeFormStyle.css");
@import url("./Backoffice/BackofficeListStyle.css");
@import url("./Backoffice/BackofficeListStyle.css");




/* IMPORT COMPONENTS STYLES */
@import url("./Shared/components/InformationMessageComponent/InformationMessageComponentStyle.css");
@import url("./Shared/components/Modales/Modales.css");
@import url("./Shared/components/Header/style.css");
@import url("./Shared/components/Navigation/style.css");
@import url("./Shared/components/LoadingComponent/LoadingComponentStyle.css");
@import url("./Shared/components/Footer/style.css");
@import url("./Shared/components/BackofficeGoBack/style.css");



/* MEDIAQUERIES TO PASTE */

/* phone-breakpoint: 768px;*/

/* desktop-breakpoint: 968px; */

/* @media screen and (min-width: size) {} */



:root {
    --white: #fff;
    --red: rgb(219, 35, 50);
    --yellow: rgb(249, 192, 14);
    --black: #242424;
    --title-font: 'Galada', cursive;
    --text-font: 'Ubuntu', sans-serif;
    --admin-background: #166cf7;
    --admin-text-font: "Lato", sans-serif;
    --button-form: #03b903
}

html {
    font-size: .625em;
    font-size: calc(1em * .625);
}

body {
    font-family: var(--text-font);
    font-size: 1.6rem;
    line-height: 1.4;
    min-height: 100vh;
    max-width: 100vw;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

section {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

h1, h2, h3, h4 {
    font-family: var(--title-font);
}

h1 {
    font-size: 4rem;
    padding-top: 1em;
    padding-bottom: 1em;
}

h2 {
    font-size: 3rem;
    padding-top: 1em;
    padding-bottom: 1em;
}

h3 {
    font-size: 2.5rem;
    margin-top: .5em;
    margin-bottom: .5em;
}

img {
    width: 100%;
    height: 100%;
    /* object-fit: contain; */
}

a {
    text-decoration: none;
    color: var(--black);
    font-family: var(--text-font);

}

q {
    font-style: italic;
}