#backoffice-list-main {

    /* padding: 5% 0; */

}

#backoffice-list-main h1 {
    font-family: var(--admin-text-font);
    text-align: center;

    border-bottom: solid 2px var(--admin-background)
}


.backoffice-list-container {
    margin: 5% 0;
    padding: 0 3%;

    overflow: hidden;
}


.backoffice-list-container .table-container {

    overflow-x: scroll;

    border-radius: 5px;


}


table {
    border-collapse: collapse;
    font-family: var(--admin-text-font);
    margin: 0 auto;
    width: 100%;
}


table td {
    min-width: 15vw;
}

table thead {
    position: sticky;
    top: 0;
}



table thead th {
    background-color: var(--admin-background);
    color: #ffffff;
    font-weight: bold;
    font-size: 1em;
    padding: 1%;



}


table tbody td div {
    max-height: 15vh;
    padding: 2%;
}

table tbody td {
    color: var(--black);
    border: 1px solid var(--admin-background);
    padding: 1em;


    font-weight: bold;

    overflow-y: scroll;
}


table tbody tr {
    background-color: #f9fafb;
}

table tbody tr:nth-child(odd) {
    background-color: #ffffff;
}



.list-button {
    width: 90%;
    color: var(--white);
    font-weight: bold;
    border: none;
    border-radius: 5px;

    cursor: pointer;

    padding: 5%;

    display: block;
    margin: auto;


}

.delete-button {
    background-color: var(--red);

}

.update-button {
    background-color: var(--yellow);

}

.deactivate-button {
    background-color: #dd5735;

}

.backoffice-list-create-button {
    display: block;
    padding: 2%;
    background-color: var(--admin-background);
    border: none;
    border-radius: 8px;
    text-align: center;
    color: var(--white);
    width: 33%;
    margin: 0 auto 3% auto;
}

@media screen and (min-width: 968px) {
    .list-button {
        width: 100%;
        padding: 3% 0;
    }

    table td {
        min-width: 10vw;
    }

    table tbody td div {
        max-height: 10vh;
        /* padding: 2%; */
    }
}