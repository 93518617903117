.presentation-video-container {
    width: 100%;
    object-fit: contain;
    background-color: var(--yellow);
}

.home-right-section {
    text-align: right;
    background-color: var(--yellow);
    margin-top: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 95%);
    padding-bottom: 30vw;
}

.home-right-section article {
    min-height: 10em;
}

.home-right-section h3 {
    color: var(--red);
}

.yellow-left-right {
    height: 3vh;
    width: 100%;
    background: linear-gradient(to left top, rgb(249, 192, 14) 50%, #fff 50%);

    position: absolute;
    top: 4vh;
}

.yellow-fill {
    background-color: var(--yellow);
    height: 3vh;
    width: 100%;

    position: absolute;
    top: 100%;
}


.home-nos-realisations {
    position: relative;
}

.realisations-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1px;

}

.home-realisation-card {
    width: calc(50% - 1px);
    aspect-ratio: 1/1;
}

.home-realisation-card img {
    object-fit: cover;
}


.review-card {
    height: 18em;
    width: 80%;
    margin: 1em auto 2em;
    border-radius: 1em;
    box-shadow: 0 .5em 2em rgba(0, 0, 0, 0.3);
    background-color: #fff;
}

.review-card-header {
    display: flex;
    align-items: center;
    height: 4em;
    margin-bottom: 2%;
    border-radius: 1em 1em 0 0;
    background-color: rgb(89, 91, 242);
    color: var(--white);
}

.review-card-header .author-name-p {
    font-weight: bold;
}

.review-card-header .author-picture-container {
    width: 10%;
    margin-right: 1em;
    padding-left: 1em;
}

.review-card-body {
    position: relative;
    height: 14em;
    overflow-y: hidden;
    padding: 0 1em;
}

.fade-out::after {
    background: linear-gradient(hsla(0, 0%, 100%, 0) 70%, #fff);
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    border-radius: 1em;
}

.home-partenaires {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    margin-top: 5%;
}

.home-partenaires-picture-container {
    width: 15%;
    margin: 0 3%;
}

@media screen and (min-width: 768px) {
    .home-partenaires-picture-container {
        width: 6em;
    }


    .home-partenaires-picture-container:hover {
        transition: all .3s ease-out;
        transform: scale(1.3);
    }

    .home-realisation-card {
        width: calc(33.33% - 4px);
    }

    .home-right-section {
        padding-bottom: 10vw;
    }
}

@media screen and (min-width: 968px) {

    .presentation-video-container {
        max-height: 774px;
    }

    .home-realisation-card {
        width: calc(25% - 4px);
    }
}