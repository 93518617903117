header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;

    box-shadow: 0px 3px 10px #00000085;
    padding: 2%;
    background-color: #fff;

    z-index: 1000;
    height: 5em;
    position: relative;

}

.top-header-container,
.bottom-header-container,
.flag-wrapper {
    display: flex;
    align-items: center;
}

.top-header-container {
    justify-content: flex-end;
    width: 70%;
}

.top-header-container .contact-link-container {
    display: flex;
    align-items: center;
    gap: .5em;
}


.top-header-container .language-selection-container {
    margin-left: .5em;
}

.top-header-container .language-selection-container .flag-wrapper {
    justify-content: space-between;
    gap: .5em;
}

.top-header-container .language-selection-container .flag-wrapper .flag-picture-container {
    width: 30px;
    height: 100%;
}

.admin-navigation-container {

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

}

.admin-navigation-button:nth-child(1) {
    margin-bottom: 5%;
}

.admin-navigation-button {
    padding: 8%;
    background-color: green;
    color: var(--white);

    border: none;

    border-radius: 5px;

    font-weight: bold;

    cursor: pointer;
}

.deconnexion-button {
    background-color: var(--red);

}

@media screen and (min-width: 768px) {
    header {
        flex-direction: column;
        height: 8em;
        padding: 0.4em 1% 0;
    }

    .top-header-container {
        width: 100%;
        height: 30%;
        padding: 0.4em;
    }

    .top-header-container .language-selection-container {
        height: 100%;
        width: 10%;
    }

    .top-header-container .contact-link-container {
        width: 30%;
    }

    .top-header-container .language-selection-container .flag-wrapper {
        justify-content: space-around;
    }

    .top-header-container .language-selection-container .flag-wrapper .flag-picture-container {
        width: 40%;
        height: 90%;
    }

    .admin-navigation-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
    }

    .admin-navigation-button:nth-child(1) {
        margin: 0;
    }

    .admin-navigation-button {
        padding: 4%;
    }



}