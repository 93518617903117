#contact-main {
    padding: 5%;
}

#contact-main h1 {
    text-align: center;
    /* margin: 5% 0; */
}

#contact-main fieldset {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

#contact-main fieldset .input-group {
    width: 100%;
}

#contact-main textarea, #contact-main select {
    width: calc(100% - 2%);
    resize: vertical;
}

#contact-main input {
    width: calc(100% - 6%);

}

#contact-main button {
    padding: 1rem;
    border: none;
    border-radius: 8px;
    display: block;
    margin: 0 auto;
    background-color: var(--button-form);
    color: var(--white);

    cursor: pointer;
}

#contact-main button:hover {
    background-color: #049404;
}

.autocomplete-adress-container {
    /* padding: 2%; */
    border-radius: 0 1em;
    /* width: 70%; */
    /* margin: 0 auto; */
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.autocomplete-adress-container p {
    padding: .575em 1em;

}

.autocomplete-adress-container li:not(:last-child) {
    border-bottom: var(--black) solid 1px;
}


@media screen and (min-width: 768px) {
    #contact-main fieldset .input-group {
        width: 45%;
    }

    #contact-main .input-group {
        width: 100%;
    }

    #contact-main h1 {}
}