.loading-container {
    min-height: 90vh;
    width: 100vw;
    background-color: var(--white);

    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-lottie {
    z-index: 100;

/* 
    width: 33vw;
    height: 33vh; */
/* 
    margin: auto auto; */
}